function trackMixpanelEvent(eventName, properties) {
  try {
    if (mixpanel) {
      mixpanel.track(eventName, properties);
    } else {
      console.error("Mixpanel is not initialized.");
    }
  } catch (error) {
    console.error("Error tracking event '" + eventName + "':", error);
  }
}